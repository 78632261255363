import * as React from 'react';
import Link from 'next/link';
import { timeParse } from 'src/utilutils';
import Image from 'next/image';

interface NewContentProps {
  article: Article;
}

const NewContent = ({ article }: NewContentProps) => {
  const imageWidth = 112; // 画像の幅
  const imageHeight = 112; // 画像の高さ

  return (
    <Link
      href={`/blog/${article.id}`}
      className="card  flex flex-row items-center space-x-4 bg-gray-800 text-white w-full  sm:w-5/12 sm:h-40 p-2 shadow-md hover:shadow-lg transition-shadow overflow-hidden "
    >
      <figure className="flex-shrink-0 w-28 h-28 overflow-hidden rounded-md">
        <Image
          loading="lazy"
          decoding="async"
          src={article.featuredImage.url}
          alt="サムネイル"
          width={imageWidth}
          height={imageHeight}
          className="object-cover w-full h-full"
        />
      </figure>
      <div className="flex-1">
        <h2 className="card-title text-sm sm:text-lg font-bold ">
          {article.title}
        </h2>
        <p className="text-xs text-gray-400 sm:text-sm mt-1 line-clamp-2">
          投稿日: {timeParse(article.createdAt)}
        </p>
      </div>
    </Link>
  );
};

export default NewContent;
