'use client';
import React, { useState } from 'react';
import NewContent from '../NewContent';

interface NewContentsListProps {
  articles: Article[];
}

const NewContentsList = ({ articles }: NewContentsListProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 4;
  const indexOfLastArticle = currentPage * itemsPerPage;
  const indexOfFirstArticle = indexOfLastArticle - itemsPerPage;
  const currentArticles = articles.slice(
    indexOfFirstArticle,
    indexOfLastArticle,
  );

  const totalPages = Math.ceil(articles.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="bg-gray-800 text-gray-300  py-4 min-h-[800px]  sm:min-h-[600px] ">
      <p className=" text-2xl text-white font-bold  sm:text-4xl  text-center">
        新着記事
      </p>
      <div className="join flex flex-row space-x-1 justify-center items-center text-gray-400">
        <button
          className="join-item btn" // btn-lgを追加してボタンを大きくする
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <p className="text-3xl">«</p>
        </button>
        <button className="join-item btn btn-lg">
          <p className="text-2xl">{currentPage}</p>
        </button>
        <button
          className="join-item btn btn-lg" // btn-lgを追加してボタンを大きくする
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <p className="text-3xl">»</p>
        </button>
      </div>
      <div className="flex flex-col space-y-4 p-4 items-center sm:flex-row sm:flex-wrap sm:space-x-4 justify-center">
        {currentArticles.map((article) => {
          return <NewContent key={article.id} article={article} />;
        })}
      </div>
    </div>
  );
};

export default NewContentsList;
